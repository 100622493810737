<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备名称" prop="equipmentId" >
        <a-select v-model="form.equipmentId" placeholder="请选择设备">
          <a-select-option v-for="(item,key) in equipmentList" :key="key" :value="item.id">{{item.equipmentName}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="游戏名称" prop="gameName" >
        <a-input v-model="form.gameName" placeholder="请输入游戏名称" />
      </a-form-model-item>
      <a-form-model-item label="游戏启动地址" prop="socketUrl" >
        <a-input v-model="form.socketUrl" placeholder="请输入游戏启动地址" />
      </a-form-model-item>
      <a-form-model-item label="游戏费用" prop="price" >
        <a-input-number style="width: 200px" :min="0" :precision="2" v-model="form.price" placeholder="请输入游戏费用" />元
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="1">文举</a-radio>
          <a-radio :value="2">武举</a-radio>
          <a-radio :value="3">其他</a-radio>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getInfo, addInfo, updateInfo } from '@/api/game/info'
import {listEquipment} from '@/api/equipment/info'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      equipmentList: [],
      // 表单参数
      form: {
        id: null,

        equipmentId: null,

        gameName: null,

        price: null,

        type: null,

        socketUrl:null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        equipmentId: [
          { required: true, message: '设备名称不能为空', trigger: 'change' }
        ],
        gameName: [
          { required: true, message: '游戏名称不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '游戏费用不能为空', trigger: 'blur' }
        ],
        socketUrl: [
          { required: true, message: '游戏启动地址不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型其他不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  activated() {
    this.initEquipment()
  },
  created () {
    this.initEquipment()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    initEquipment(){
      listEquipment({isDeleted : 0}).then(res=>{
        this.equipmentList=res.data
      })
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        equipmentId: null,
        gameName: null,
        price: null,
        socketUrl: null,
        type: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInfo({"id":id}).then(response => {
        this.form = response.data
        if(this.form.type){
          this.form.type = parseInt(this.form.type)
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
